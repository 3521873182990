import React, {useEffect, useRef, useState} from 'react';
import "../data/App.css"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ThemeProvider } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import axios from "axios";
import myTheme from "../data/mui-theme";
import baseLink from "../data/api-base-link";
import SelectComponent from "../SelectComponent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {IconButton} from "@mui/material";
import ToolTip from "../ToolTip";
import Popup from "../popup";
import CircularProgress from '@mui/material/CircularProgress';
import GppGoodIcon from "@mui/icons-material/GppGood";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import UpdateIcon from "@mui/icons-material/Update";
import ErrorIcon from "@mui/icons-material/Error";

function BuyKeys({ paymentMethods, fingerPrint, gamedata, token, setToken, setError, setSuccess, discount, balance, setBalance, userdata, isAdmin, orderData}) {
    // rendering logic for middle section where you select a game based off the product
    const [selectedGame, setSelectedGame] = useState(null); // state to hold selected game for dropdown menu
    const [amountDict, setAmountDict] = useState({}); // State to hold the values of text fields
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [paymentPending, setPaymentPending] = useState(false);
    const [orderId, setOrderID] = useState(null);
    const [paymentLink, setPaymentLink] = useState("");
    const [total, setTotal] = useState(0.);
    const [paymentAddress, setPaymentAddress] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const historyListRef = useRef(null);
    const [userBal, setUserBal] = useState(balance);
    const [featurePopup, setFeaturePopup] = useState(false);
    const [allFeatures, setAllFeatures] = useState("");
    const [mediaPopup, setMediaPopup] = useState(false)

    const emojis = {
        0:{"color":"lime", "icon":GppGoodIcon},
        1:{"color":"yellow", "icon":ReportProblemIcon},
        2:{"color":"orange", "icon":ReportProblemIcon},
        3:{"color":"blue", "icon":UpdateIcon},
        4:{"color":"red", "icon":ErrorIcon}
    }

    useEffect(() => {
        const handleScroll = () => {
            const list = historyListRef.current;
            if (list) {
                const rows = list.querySelectorAll('.row');
                rows.forEach(row => {
                    const rect = row.getBoundingClientRect();
                    const listRect = list.getBoundingClientRect();

                    // Calculate visible height within the list viewport
                    const visibleHeight = Math.min(rect.bottom, listRect.bottom) - Math.max(rect.top, listRect.top);

                    // Ensure the visible height is within valid bounds
                    const clampedVisibleHeight = Math.max(0, Math.min(rect.height, visibleHeight));

                    // Calculate visibility percentage
                    const visibilityPercentage = clampedVisibleHeight / rect.height;

                    // Apply styles based on visibility
                    if (visibilityPercentage > 0 && visibilityPercentage < 1) {
                        row.style.opacity = visibilityPercentage.toString();
                        row.style.transform = `scale(${visibilityPercentage})`;
                        row.style.visibility = 'visible';
                    } else if (visibilityPercentage <= 0) {
                        row.style.opacity = '0';
                        row.style.transform = 'scale(0)';
                        row.style.visibility = 'hidden'; // Hide the row completely
                    } else {
                        row.style.opacity = '1';
                        row.style.transform = 'scale(1)';
                        row.style.visibility = 'visible';
                    }
                });
            }
        };

        const list = historyListRef.current;
        if (list) {
            list.addEventListener('scroll', handleScroll);
            // Call handleScroll initially to set the correct state on load
            handleScroll();
        }

        return () => {
            if (list) {
                list.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);


    const handleCopy = (text, copyMessage) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setSuccess(copyMessage);
                // You can add a success message or other feedback here if needed
            })
            .catch((err) => {
                setError('Failed to copy to clipboard');
            });
    };

    const handlePurchase = () => {
        setPaymentPending(false)
        setPaymentLink("")
        setPaymentAddress("")
        setOrderID("")
        setPaymentMethod("")
        if (!selectedGame) {
            setError("Please select game and amount")
            return
        }
        if (!amountDict) {
            setError("Please select amount of keys to purchase")
            return
        }
        const hasPositiveValue = Object.values(amountDict).some(value => value > 0);
        if (!hasPositiveValue) {
            setError("You must buy at least 1 key")
            return
        }
        setIsPopupOpen(!isPopupOpen);
    };

    const handlePaymentMethod = async (method) => {
        setPaymentPending(true)
        // send payment request and handle payment details
        try
        {
            switch (method) {
                case "balance":
                    if (userBal < total) {
                        setError("Not enough balance")
                        setIsPopupOpen(false)
                        return
                    }
                    setPaymentMethod("balance")
                    const balresponse = await axios.post(`https://${baseLink}/order/${token}/balance/${selectedGame}`, {data: amountDict, userdata: {fingerprint: fingerPrint}});
                    if (balresponse.status === 200) {
                        setSuccess("Order received, being processed.")
                        if (balresponse && balresponse.data[0] && balresponse.data[0].oid && balresponse.data[0].ototal) {
                            setUserBal(parseFloat(userBal) - parseFloat(total))
                            setOrderID(balresponse.data[0].oid)
                            setTotal(balresponse.data[0].ototal)

                        }
                    }

                    break;


                case "crypto":
                    setPaymentMethod("crypto")
                    const hpresponse = await axios.post(`https://${baseLink}/order/${token}/crypto/${selectedGame}`,
                        {data: amountDict, userdata: {fingerprint: fingerPrint}},
                        {
                            timeout: 60000 // Set timeout to 60 seconds (60000 ms)
                        });

                    if (hpresponse.status === 200) {
                        if (hpresponse.data[0]) {
                            setOrderID(hpresponse.data[0].orderid)
                            setPaymentAddress(hpresponse.data[0].paymentdata.address)
                            setTotal(hpresponse.data[0].paymentdata.amount.toFixed(8))


                        }
                    }
                    break;
                case "paypal":
                    setPaymentMethod("paypal")
                    const ppresponse = await axios.post(`https://${baseLink}/order/${token}/paypal/${selectedGame}`, );
                    if (ppresponse.status === 200) {
                        if (ppresponse.data[0] && ppresponse.data[0].oid && ppresponse.data[0].paypal && ppresponse.data[0].ototal) {
                            setOrderID(ppresponse.data[0].oid)
                            setPaymentAddress(ppresponse.data[0].paypal)
                            setTotal(ppresponse.data[0].ototal)
                        }
                    }
                    break;
                case "cashapp":
                    setPaymentMethod("cashapp")
                    const caresponse = await axios.post(`https://${baseLink}/order/${token}/cashapp/${selectedGame}`, {data: amountDict, userdata: {fingerprint: fingerPrint}});
                    if (caresponse.status === 200) {
                        if (caresponse.data[0] && caresponse.data[0].oid && caresponse.data[0].cashtag && caresponse.data[0].ototal) {
                            setOrderID(caresponse.data[0].oid)
                            setPaymentAddress(caresponse.data[0].cashtag)
                            setTotal(caresponse.data[0].ototal)
                        }

                    } break;
                case "moneymotion":
                    setPaymentMethod("moneymotion")
                    const mmresponse = await axios.post(`https://${baseLink}/order/${token}/moneymotion/${selectedGame}`,{data: amountDict, userdata: {fingerprint: fingerPrint}});
                    if (mmresponse.status === 200) {
                        if (mmresponse.data[0] && mmresponse.data[0].oid && mmresponse.data[0].olink) {
                            setOrderID(mmresponse.data[0].oid)
                            setPaymentLink(mmresponse.data[0].olink)
                            setTotal(mmresponse.data[0].ototal)
                        }
                    }
                    break;
                    setPaymentMethod("stripe")
                    const stresponse = await axios.post(`https://${baseLink}/order/${token}/stripe/${selectedGame}`, {data: amountDict, userdata: {fingerprint: fingerPrint}});
                    if (stresponse.status === 200) {
                        if (stresponse.data[0] && stresponse.data[0].oid && stresponse.data[0].olink) {
                            stresponse(stresponse.data[0].oid)
                            setPaymentLink(stresponse.data[0].olink)
                            setTotal(stresponse.data[0].ototal)
                        }
                    }
                    break;
                default:
                    setError("An unexpected error occurred.")
                    break;
            }
        }
        catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Token expired, please login again.");
                    setToken(null);

                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                }
                else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                }
                else if (error.response.status === 400) {
                    setIsPopupOpen(false);
                    setError(error.response.data[0]);
                }
            } else {
                console.log(error)
                setError("An unexpected error occurred.");
            }
        }
    }


    const handleMediaPopup = () => {
        setMediaPopup(!mediaPopup)
    }



    const handleGameSelection = (selectedGame) => {
        setSelectedGame(selectedGame);
        let featureList = ""
        if (selectedGame === 'balance' || selectedGame === "Balance") {
            return
        }
        for (let [name, features] of Object.entries(gamedata[selectedGame].features)) {
            featureList += `**${name.toUpperCase()}**\n${features}\n\n\n`
        }
        setAllFeatures(featureList)
        if (selectedGame==="balance") {
            setAmountDict({balance:0})
        }


        // Check if the selected game exists in gamedata
        if (gamedata[selectedGame] && gamedata[selectedGame].lengths) {
            // Update the dictionary with new values for the selected game
            const newDict = {};
            Object.keys(gamedata[selectedGame].lengths).forEach((length) => {
                // Check if the length already exists in the existing dictionary
                // If it does, keep the existing value; otherwise, set it to 0
                newDict[length] = amountDict[length] || 0;
            });
            setAmountDict(newDict);
        }
    };

    const setBalDict = (value) => {
        // Remove leading zeros (except for "0.")
        value = value.replace(/^0+(?!\.|$)/, '');

        // Ensure only one period is allowed
        const parts = value.split('.');
        if (parts.length > 2) {
            // If there are multiple periods, join all parts except the last one
            value = parts.slice(0, 2).join('.') + parts.slice(2).join('');
        }

        // Allow empty input to set balance to 0
        if (value === "") {
            setAmountDict({ Balance: 0 });
            setTotal(0);
            return;
        }

        // Check if the value contains a valid float
        let balValue = parseFloat(value);

        // Check if the parsed value is NaN or negative
        if (isNaN(balValue) || balValue < 0) {
            balValue = 0;
        }

        // Update the state with the new amountDict
        setAmountDict({ Balance: value }); // Keep the string value for input
        setTotal(balValue); // Set the parsed float value for calculations
    };



    const setDict = (length, value) => {
        // Parse the value as an integer
        let intValue = parseInt(value);

        // Check if the parsed value is NaN or negative
        if (isNaN(intValue) || intValue < 0) {
            // If NaN or negative, set the value to 0
            intValue = 0;
        }

        // Create a new amountDict with the updated value
        const newAmountDict = {
            ...amountDict,
            [length]: intValue
        };

        // Update the state with the new amountDict
        setAmountDict(newAmountDict);

        // Calculate total cost of all keys based on the new amountDict
        let totalCost = 0;

        if (selectedGame && !(selectedGame === "balance") && gamedata[selectedGame].custompricing) {

            for (const [length, keyAmount] of Object.entries(newAmountDict)) {
                let actualPrice = 0
                for (const [minAmount, cost] of Object.entries(gamedata[selectedGame].custompricing[length])) {
                    if (minAmount <= keyAmount) {
                        actualPrice = cost
                    }
                }
                totalCost += actualPrice * keyAmount
            }
        }
        else {
            const gameLengths = gamedata[selectedGame].lengths;
            for (const [length, amount] of Object.entries(newAmountDict)) {
                const price = parseFloat(gameLengths[length]);
                if (!isNaN(price)) {
                    totalCost += price * amount;
                }
            }
            totalCost = totalCost * (1-discount)
        }

        setTotal(totalCost.toFixed(2)); // to keep the cost to two decimal places
    };


    const handleFeaturePopup = () => {
        setFeaturePopup(!featurePopup)
    }

    return (
        <div className="PageDefaults">
            <Popup toggleOpen={handlePurchase} isOpen={isPopupOpen}>
                {
                    paymentPending ? (

                        // Render content for completed payment
                        <>
                            {orderId ? (
                                <>
                                    <div style={{marginTop: "2ch", marginBottom: "2ch"}}><em>Order ID:</em> {orderId}
                                        <IconButton className="copyButton"
                                                    onClick={() => handleCopy(orderId, "Order ID copied to clipboard")}
                                                    size={"small"}>
                                            <ContentCopyIcon sx={{
                                                fontSize: 20,
                                                color: "white",
                                                backgroundColor: "transparent"
                                            }}/>
                                        </IconButton>
                                    </div>
                                    <div className={"pendingOrderFlexbox"}>
                                        <div className={"pendingOrderInfo"}>

                                            <div className={"textDeco"}>
                                                <em>Method: </em>{paymentMethod === 'crypto' ? 'Litecoin' : paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)}
                                            </div>
                                            {/* Conditionally render based on paymentAddress */}
                                            {paymentAddress && (
                                                <div className={"textDeco"}><em>Send to:</em> {paymentAddress.length > 18 ? (paymentAddress.substring(0,18)+"...") : (paymentAddress)}<IconButton className="copyButton"
                                                                                         onClick={() => handleCopy(paymentAddress, "Payment Address copied to clipboard")}
                                                                                         size={"small"}>
                                                    <ContentCopyIcon sx={{
                                                        fontSize: 20,
                                                        color: "white",
                                                        backgroundColor: "transparent"
                                                    }}/>
                                                </IconButton><br/></div>
                                                // This displays the payment address as text
                                            )}
                                            <div className={"textDeco"}>
                                                <em>Total:</em>
                                                {paymentMethod === "crypto" ? (
                                                    <div>
                                                        {total}
                                                        <IconButton className="copyButton" onClick={() => handleCopy(total, "Order total copied to clipboard")} size={"small"}>
                                                            <ContentCopyIcon sx={{
                                                                fontSize: 20,
                                                                color: "white",
                                                                backgroundColor: "transparent"
                                                            }}/>
                                                        </IconButton>
                                                    </div>
                                                ) : (
                                                    <>
                                                        $ {(total*paymentMethods[paymentMethod].fee).toFixed(2)}
                                                    </>
                                                )}



                                            </div>

                                        </div>
                                        <div className={"pendingOrderPayment"}>
                                            {paymentMethod === "paypal" ? (
                                                <p>Send as Friends and Family ONLY.</p>
                                            ) : paymentMethod === "cashapp" ? (
                                                <p>The Order ID MUST be included in the cashapp note, case
                                                    sensitive.</p>
                                            ) : paymentMethod === 'crypto' ? (
                                                <p>You must send the EXACT amount (after crypto fees). Your order will automatically confirm, check your DM's and order page for delivery.</p>
                                            ) : (
                                                <p>Order will be sent via DM, it can also be seen in orders tab after
                                                    page refresh.</p>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <ThemeProvider theme={myTheme} >
                                    <CircularProgress />
                                </ThemeProvider>

                            )}


                            {/* Conditionally render based on paymentLink */}
                            {paymentLink && (
                                <button onClick={() => window.open(paymentLink, '_blank')}>Pay Now</button>
                                // This button opens the payment link in a new tab when clicked


                            )}


                        </>
                    ) : (
                        <>
                            <span className="close-btn" onClick={handlePurchase}>&times;</span>
                            <h2>Select Payment Method</h2>
                            <div className="buttons">
                                {paymentMethods && Object.entries(paymentMethods).map(([name, data], index) => (
                                    <>
                                        {data.enabled && (
                                            <button onClick={() => handlePaymentMethod(name)}>
                                                {name} {data.fee > 1 && `${((data.fee - 1) * 100).toFixed(2)} %`}
                                            </button>
                                        )}
                                    </>

                                ))}

                            </div>
                        </>
                    )
                }
            </Popup>

            <div className={"BoxItemFlexBox"}>
                <div className="BuyKeys">
                    <div className="InfoSection">
                        <h2>Select Product</h2>
                        <div className="BoxItem">
                            <div>

                                <p>Select Product</p>

                                <ThemeProvider theme={myTheme}>
                                    <Box sx={{
                                        '& > :not(style)': {
                                            m: 1,
                                            width: '25ch',
                                            color: myTheme.palette.secondary.main,
                                            backgroundColor: myTheme.palette.background.main,
                                        },
                                        '& .MuiSelect-icon': {
                                            color: myTheme.palette.primary.main, // Set arrow color to match primary color
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color to match primary color
                                            },
                                            '&:hover fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color on hover to match primary color
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color when focused to match primary color
                                            },
                                        },
                                    }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"
                                                        style={{color: myTheme.palette.primary.main}}>
                                                {"Select Game"}
                                            </InputLabel>
                                            <Select
                                                label={"Select Game"}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                onChange={(event) => handleGameSelection(event.target.value)}
                                                sx={{color: "white"}}>
                                                <MenuItem key={999} value={"balance"}>Balance</MenuItem>
                                                {gamedata && Object.keys(gamedata).map((game, index) => (
                                                    <MenuItem key={index} value={game}>{game}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </ThemeProvider>

                                {selectedGame && selectedGame === "balance" && (
                                    <div className={"TextBoxes"}>
                                        <p>Balance Topup</p>
                                        <ThemeProvider theme={myTheme}>
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& > :not(style)': {
                                                        m: 1,
                                                        width: '25ch',
                                                        color: "white",
                                                        backgroundColor: myTheme.palette.background.main,
                                                    },
                                                    '& input': {
                                                        color: 'white', // Set text color to white
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: myTheme.palette.primary.main,
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: myTheme.palette.primary.main,
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: myTheme.palette.primary.main,
                                                        },
                                                    },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <TextField
                                                    type="text"
                                                    onChange={(event) => setBalDict(event.target.value.replace(/^0+/, ''))}
                                                    color="primary"
                                                    label="Amount"
                                                    variant="outlined"
                                                    placeholder={"Topup Amount"}
                                                    focused
                                                    value={amountDict["Balance"]}
                                                    inputProps={{min: "0", maxLength: 7}}
                                                />
                                            </Box>
                                        </ThemeProvider>
                                    </div>
                                )}

                                {gamedata && gamedata[selectedGame] && gamedata[selectedGame].status && (() => {
                                    const statusColor = gamedata[selectedGame].statuscolor; // Extract status color
                                    const iconColor = emojis[statusColor].color
                                    const Icon = emojis[statusColor].icon || null; // Get the corresponding emoji or default to null

                                    return (
                                        <p>
                                            <div className={"hSort"} style={{
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            gap: "0.5ch",
                                            flexWrap:"nowrap"
                                        }}>
                                            {Icon &&
                                            <Icon sx={{color: iconColor}}/>} <>{gamedata[selectedGame].status}</>
                                        </div>
                                        </p>
                                );
                                })()}

                                {
                                    selectedGame && gamedata[selectedGame] && gamedata[selectedGame].lengths &&
                                    Object.keys(gamedata[selectedGame].lengths)
                                        .sort((a, b) => parseInt(a) - parseInt(b))
                                        .map((length, index) => {
                                        return (

                                            <div>
                                                <p>{length.charAt(0).toUpperCase() + length.slice(1)}</p>
                                                <ThemeProvider theme={myTheme}>
                                                    <Box
                                                        component="form"
                                                        sx={{
                                                            '& > :not(style)': {
                                                                m: 1,
                                                                width: '25ch',
                                                                color: "white",
                                                                backgroundColor: myTheme.palette.background.main,
                                                            },
                                                            '& input': {
                                                                color: 'white', // Set text color to white
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                },
                                                            },
                                                        }}
                                                        noValidate
                                                        autoComplete="off"
                                                    >
                                                        <TextField
                                                            type="text" // Change type to "text"
                                                            onChange={(event) => setDict(length, event.target.value.replace(/^0+/, ''))} // Remove leading zeros
                                                            color="primary"
                                                            label={"Amount"}
                                                            variant="outlined"
                                                            focused
                                                            value={amountDict[length]} // Display the value as is from the state
                                                            inputProps={{min: "0", maxLength: 7}}
                                                        />
                                                    </Box>
                                                </ThemeProvider>

                                            </div>
                                        );
                                    })}


                            </div>
                        </div>
                    </div>
                </div>
                {gamedata && selectedGame && !(selectedGame === "balance") && gamedata[selectedGame] && gamedata[selectedGame].custompricing && (
                    <div className={"vSort"} style={{maxWidth: "32ch"}}>
                        <h2 style={{textAlign: "center"}}>Custom Pricing Details</h2>
                        <div className={"BoxItem"}>
                            <p>This product has custom pricing, ignore your discount and reference the list below. </p>
                            <div className={"BoxScrollContainer"} style={{maxHeight: "60vh"}}>
                                {Object.entries(gamedata[selectedGame].custompricing)
                                    .sort(([a], [b]) => parseInt(a) - parseInt(b))
                                    .map(([pLength, amtDict]) => (
                                    <div className={"vSort"}>
                                        <p><em>{pLength}</em></p>

                                        {Object.entries(amtDict).map(([limit, price]) => (
                                            <p style={{paddingLeft: "2ch"}}><em>{limit} Keys:</em> $ {price}</p>
                                        ))}

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                <div>
                    <h2 style={{textAlign: "center"}}>Order Details</h2>

                    <div className={"BoxItem"}>
                        {isAdmin && userdata && (
                            <SelectComponent menuItems={userdata}/>
                        )}
                        <div className={"orderDetailsFlexbox"}>
                            <div>
                                <p><em>Discount:</em> {(selectedGame && !(selectedGame === "balance") && gamedata[selectedGame].custompricing) ? ("N/A") : (`${discount * 100} %`)} </p>
                                <p><em>Total:</em> $ {total}</p>
                                <p><em>Balance:</em> $ {userBal}</p>
                                <button onClick={handlePurchase}>
                                    Purchase
                                </button>
                            </div>
                            <div className={"cost"}>
                                {Object.entries(amountDict)
                                    .sort(([a], [b]) => parseInt(a) - parseInt(b))
                                    .map(([key, val]) => {
                                    return (
                                        <p key={key}>
                                            <em>{key}:</em> {val}
                                        </p>
                                    );
                                })}
                            </div>

                        </div>
                    </div>
                    <h2 style={{textAlign: "center"}}>Product Info</h2>
                    <Popup toggleOpen={handleFeaturePopup} isOpen={featurePopup} size={"large"}>
                        <div className={"BoxScrollContainer"}>
                            <div className={"hSort"}>
                                {selectedGame && !(selectedGame==="balance") && Object.entries(gamedata[selectedGame].features).sort(([a], [b]) => {
                                    // Ensure "requirements" comes first
                                    if (a === "requirements") return -1;
                                    if (b === "requirements") return 1;
                                    return 0;
                                }).map(([name, features]) => (
                                    <>
                                        {!(selectedGame === 'balance') && !(features === "") && (
                                            <div className="vSort" style={{width: "20ch"}}>
                                                <div className="toolTipContainer" style={{marginTop: "2ch"}}>
                                                    <p><em>{name.charAt(0).toUpperCase() + name.slice(1)}</em>
                                                        <IconButton className="copyButton"
                                                                    onClick={() => handleCopy(features, `${name.charAt(0).toUpperCase() + name.slice(1)} Features copied to clipboard`)}
                                                                    size={"small"}>
                                                            <ContentCopyIcon sx={{
                                                                fontSize: 20,
                                                                color: "white",
                                                                backgroundColor: "transparent"
                                                            }}/>
                                                        </IconButton>
                                                        <br/></p>
                                                    <ToolTip
                                                        tooltipText={"You can scroll through features if some are not visible"}/>
                                                </div>
                                                <div className="featureList">
                                                    {!(selectedGame === 'balance') && features.split('\n').map((line, index) => (
                                                        <React.Fragment key={index}>
                                                            {!(line === "") && (
                                                                <>
                                                                    <em>•</em> {line.replace(/^- /, '')}
                                                                    <br/>
                                                                </>
                                                            )}

                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    </Popup>

                    <div className={"BoxItem"}>
                        {gamedata && selectedGame && !(selectedGame === "balance") ? (
                            <>
                                {!(selectedGame === 'balance') && !(selectedGame === "Balance") && !(Object.keys(gamedata[selectedGame].features).length === 0) ? (
                                    <>
                                        <p>Feature List
                                            <IconButton className="copyButton"
                                                        onClick={() => handleCopy(allFeatures, "All Features copied to clipboard")}
                                                        size={"small"}>
                                                <ContentCopyIcon sx={{
                                                    fontSize: 20,
                                                    color: "white",
                                                    backgroundColor: "transparent"
                                                }}/>
                                            </IconButton></p>
                                        <div>
                                            <button style={{marginTop: 0}} onClick={handleFeaturePopup}>View Features
                                            </button>

                                        </div>
                                    </>
                                ) : (
                                    <p>Features have not been provided.</p>
                                )}

                                {(!(gamedata[selectedGame].images === "") || !(gamedata[selectedGame].videos === "")) ? (
                                    <div>
                                        <p>Product Images and Videos</p>
                                        <button style={{marginTop: 0}} onClick={handleMediaPopup}>
                                            View Media
                                        </button>
                                    </div>
                                ) : (
                                    <p>Media has not been provided.</p>
                                )}
                                            <Popup toggleOpen={handleMediaPopup} isOpen={mediaPopup} size={"large"}>
                                                <div className={"BoxScrollContainer"}>
                                                    <div className={"hSort"} style={{flexBasis: "33.33%"}}>
                                                        <div className={"vSort"}>
                                                            <p>Product <em>Images</em></p>
                                                            <div className={"BoxScrollContainer"}>
                                                                {gamedata[selectedGame].logo && (
                                                                    <img src={gamedata[selectedGame].logo} alt={"logo"}
                                                                         style={{
                                                                             maxWidth: "400px",
                                                                             marginTop: "2ch",
                                                                             marginBottom: "2ch"
                                                                         }}/>
                                                                )}
                                                                {gamedata[selectedGame].images.split('\n').map((link, index) => (
                                                                    <>
                                                                        {!(link === "") && (
                                                                            <div key={index}>
                                                                                <img src={link} alt="noindex"
                                                                                     className={"mediaDeco"}/>
                                                                            </div>
                                                                        )}
                                                                    </>

                                                                ))}
                                                            </div>
                                                        </div>
                                                        {gamedata[selectedGame].videos && (
                                                            <div className={"vSort"}>
                                                                <p>Product <em>Videos</em></p>
                                                                {gamedata[selectedGame].videos.split('\n').map((link, index) => (
                                                                    <div key={index}>
                                                                        <iframe src={link} title={link}
                                                                                className={"mediaDeco"}/>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )
                                                        }

                                                    </div>
                                                </div>
                                            </Popup>

                                {gamedata[selectedGame].loader ? (
                                    <>
                                        <p>Loader and Instructions</p>
                                        <div>
                                            <button style={{marginTop: 0}}
                                                    onClick={() => window.open(gamedata[selectedGame].loader, "_blank")}>Download
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <p>A loader has not been provided.</p>
                                )}
                                {gamedata[selectedGame].instructions ? (
                                    <>
                                        <div>
                                            <button
                                                onClick={() => window.open(gamedata[selectedGame].instructions, "_blank")}>View
                                                Instructions
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <p>Instructions have not been provided.</p>
                                )}
                            </>
                        ) : (
                            <p>Please select a product.</p>
                        )}


                    </div>
                </div>
            </div>

        </div>


    );
}

export default BuyKeys;